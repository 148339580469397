import React, { Component } from 'react';
import '../Css/Components/Contact.css';

class FormContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
        }
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        })
    }

    onFieldChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();

        window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise.execute('6Le_rZ4aAAAAAPODg-nm3iRfGzWB85v5cRT9k7Gq', { action: 'SEND_FORM' })
                .then((token) => {
                    let body = this.state;
                    body.token = token;
                    fetch(localStorage.getItem("api") + "/email", {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                    }).then(
                        (response) => (response.json())
                    ).then((response) => {
                        if (response.status === 'success') {
                            alert("Message Sent");
                            this.resetForm()
                        } else if (response.status === 'failed') {
                            alert("Message failed to send")
                        } else {
                            console.log(response)
                        }
                    })
                });
        });

    }

    render() {
        return (
            <div id="FormContact">
                <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div>
                        <label htmlFor="name">Name:</label><br />
                        <input type="text" placeholder="Name" name="name" id="name"
                            value={this.state.name} onChange={this.onFieldChange.bind(this)} required />
                    </div>

                    <div>
                        <label htmlFor="email">Email:</label><br />
                        <input type="email" placeholder="Email" name="email" id="email"
                            value={this.state.email} onChange={this.onFieldChange.bind(this)} required />
                    </div>

                    <div>
                        <label htmlFor="subject">Subject:</label><br />
                        <input type="text" placeholder="Subject" name="subject" id="subject"
                            value={this.state.subject} onChange={this.onFieldChange.bind(this)} required />
                    </div>

                    <div>
                        <label htmlFor="message">Message:</label><br />
                        <textarea placeholder="Message" required name="message" id="message"
                            value={this.state.message} onChange={this.onFieldChange.bind(this)}>
                        </textarea>
                    </div>

                    <div id="privacy-policy">
                        <span>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"> Privacy Policy </a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
                    </div>

                    <br />

                    <input type="submit" value="SEND" />
                </form>
            </div>
        )
    }
}

export default FormContact;