import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

import '../Css/Pages/Landing.css';
import Map from '../Components/templates/map/map';

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imageSection3: 1,
            imagesSection6: [1, 2],
            mapState: false,
        };
    }

    generateImagesRandomBanner = (day) => {
        let images = [];
        let stateNum = true;
        let contador = 0;

        while (contador < 4) {
            let num = Math.round(Math.random() * 41);

            if (num != 0) {
                for (let j = 0; j < contador; j++) {
                    if (num === images[j]) {
                        stateNum = false;
                    }
                }

                if (stateNum) {
                    images.push(num);
                    contador++;
                }
            }
        }

        this.setState({ images });
        localStorage.setItem('date-image', day.getDate());
        localStorage.setItem('numImages', images);
    };

    generateImageSection3 = (day) => {
        let image = 1;
        let contador = 0;

        while (contador < 1) {
            let num = Math.round(Math.random() * 27);

            if (num != 0) {
                image = num;
                contador++;
            }
        }

        this.setState({ imageSection3: image });
        localStorage.setItem('date-image-section-3', day.getDate());
        localStorage.setItem('numImage-section-3', image);
    };

    generateImagesSection6 = (day) => {
        let images = [];
        let stateNum = true;
        let contador = 0;

        while (contador < 2) {
            let num = Math.round(Math.random() * 40);

            if (num != 0) {
                for (let j = 0; j < contador; j++) {
                    if (num === images[j]) {
                        stateNum = false;
                    }
                }

                if (stateNum) {
                    images.push(num);
                    contador++;
                }
            }
        }

        this.setState({ imagesSection6: images });
        localStorage.setItem('date-image-section-6', day.getDate());
        localStorage.setItem('numImages-section-6', images);
    };

    componentDidMount() {
        // Images random banner
        let dayToday = new Date();

        if (localStorage.getItem('date-image')) {
            let day = localStorage.getItem('date-image');

            if (day != dayToday.getDate()) {
                this.generateImagesRandomBanner(dayToday);
            } else {
                this.setState({
                    images: localStorage.getItem('numImages').split(','),
                });
            }
        } else {
            this.generateImagesRandomBanner(dayToday);
        }

        // Image random section3
        if (localStorage.getItem('date-image-section-3')) {
            let day = localStorage.getItem('date-image-section-3');

            if (day != dayToday.getDate()) {
                this.generateImageSection3(dayToday);
            } else {
                this.setState({
                    imageSection3: localStorage.getItem('numImage-section-3'),
                });
            }
        } else {
            this.generateImageSection3(dayToday);
        }

        // Images random section6
        if (localStorage.getItem('date-image-section-6')) {
            let day = localStorage.getItem('date-image-section-6');

            if (day != dayToday.getDate()) {
                this.generateImagesSection6(dayToday);
            } else {
                this.setState({
                    imagesSection6: localStorage
                        .getItem('numImages-section-6')
                        .split(','),
                });
            }
        } else {
            this.generateImagesSection6(dayToday);
        }
    }
    ocultar = () => {
        alert('hola');
    };
    render() {
        const { images, imageSection3, imagesSection6, mapState } = this.state;

        return (
            <div className="container-fluid" id="Landing">
                <Navbar />

                <div className="custom-carousel-container">
                    <div className="carousel-container">
                        <Carousel>
                            <div className="custom-carousel-item custom-carousel-item-first">
                                <img
                                    src={
                                        '/Assets/Images/2400_/' +
                                        images[0] +
                                        '.jpg'
                                    }
                                    alt="slider"
                                />
                            </div>
                            <div className="custom-carousel-item">
                                <img
                                    src={
                                        '/Assets/Images/2400_/' +
                                        images[1] +
                                        '.jpg'
                                    }
                                    alt="slider"
                                />
                            </div>
                            <div className="custom-carousel-item">
                                <img
                                    src={
                                        '/Assets/Images/2400_/' +
                                        images[2] +
                                        '.jpg'
                                    }
                                    alt="slider"
                                />
                            </div>
                            <div className="custom-carousel-item">
                                <img
                                    src={
                                        '/Assets/Images/2400_/' +
                                        images[3] +
                                        '.jpg'
                                    }
                                    alt="slider"
                                />
                            </div>
                        </Carousel>
                    </div>
                    <div className="informative_section container">
                        <div className="row">
                            <h2 className="col-12">
                                Best way to use this site is to search for parks
                                that pique your interest using the map below.
                            </h2>
                            <ul className="col-12">
                                <li>
                                    Step 1: Use the map filters to narrow down
                                    the parks containing what you're seeking.
                                </li>
                                <li>
                                    Step 2: Once you've found a park, go to its
                                    page and discover more. If it's a park with
                                    high visitation, consider visiting a park
                                    with similar attractions but with lower
                                    visitation.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <Map className="map-landing">
                            <iframe
                                id="map-landing"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                src="https://mhyde.carto.com/builder/3f02390b-5053-44f5-9551-72a5cb115f1e/embed"
                                allowFullScreen={true}
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                oallowfullscreen="true"
                                msallowfullscreen="true"
                            ></iframe>
                        </Map>
                    </div>
                </div>

                <div className="collage">
                    <div className="top row">
                        <div className="col s12 l6">
                            <img
                                src={
                                    '/Assets/Images/Landingsection031200x800/' +
                                    imageSection3 +
                                    '.jpg'
                                }
                                alt="slider"
                            />
                        </div>
                        <div className="col s12 l6">
                            <span>
                                Deforestation, ranching, mining, corruption. All
                                threats to National Parks and reserves in
                                developing countries countries and some of these
                                parks contain many of the last intact habitats
                                for multiple species
                            </span>
                        </div>
                    </div>
                    <div className="bottom row">
                        <div className="left-portion col s12 l4">
                            <div className="orange-top-bar col s12"></div>
                            <div className="center-img col s12">
                                <img
                                    src="/Assets/Images/bottom-collage-1.jpg"
                                    alt="slider"
                                />
                            </div>
                            <div className="bottom-text col s12">
                                <div className="box-behind-text">
                                    <span className="bordered-text">
                                        WHY PARKS?
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="center-portion col s12 l4">
                            <div className="top-text show-desk-2 col s12">
                                <div className="box-behind-text">
                                    <span className="bordered-text">
                                        WHY ECOTOURISM?
                                    </span>
                                </div>
                            </div>
                            <div className="bottom-img col s12">
                                <img
                                    src="/Assets/Images/bottom-collage-2.jpg"
                                    alt="slider"
                                />
                            </div>
                            <div className="top-text show-tablet col s12">
                                <div className="box-behind-text">
                                    <span className="bordered-text">
                                        WHY ECOTOURISM?
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="right-portion col s12 l4">
                            <div className="orange-top-bar col s12"></div>
                            <div className="center-img col s12">
                                <img
                                    src="/Assets/Images/bottom-collage-3.jpg"
                                    alt="slider"
                                />
                            </div>
                            <div className="bottom-text col s12">
                                <div className="box-behind-text">
                                    <span className="bordered-text">
                                        WHY THE FOCUS ON DEVELOPING COUNTRIES?
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secction-1 row">
                    <div className="col s12">
                        <span>
                            How Enduring Parks can help you make good travel
                            decisions for parks and nature
                        </span>
                    </div>
                    <div className="col s12">
                        <div className="row">
                            <div className="col s12 m5 xl4">
                                <img src="/Assets/Images/image-circle-1.png" />
                            </div>
                            <div className="col s12 m7 xl8">
                                <span>
                                    EXPLORE THE PARKS <br /> ON THE MAP
                                </span>
                            </div>
                        </div>

                        <div className="row show-android">
                            <div className="col s12 m6">
                                <img src="/Assets/Images/image-circle-2.png" />
                            </div>
                            <div className="col s12 m6">
                                <span>
                                    Choose a less visited park <br />
                                    or a Park under threat
                                </span>
                            </div>
                        </div>

                        <div className="row show-desk">
                            <div className="col s12 m7 xl8">
                                <span>
                                    Choose a less visited park <br />
                                    or a Park under threat
                                </span>
                            </div>
                            <div className="col s12 m5 xl4">
                                <img src="/Assets/Images/image-circle-2.png" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m5 xl4">
                                <img src="/Assets/Images/image-circle-3.png" />
                            </div>
                            <div className="col s12 m7 xl8">
                                <span>
                                    Choose an Ecotour business <br /> with
                                    practices good <br /> for conservation
                                </span>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>

                <div className="secction-2">
                    <div className="col s12">
                        <div className="row">
                            <div className="col s12">
                                <span>What’s good for conservation?</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12">
                                <span>
                                    1. Traveling to less visited Parks instead
                                    of Parks overrun by the masses.
                                </span>
                                <p>
                                    See similar attractions with less crowds{' '}
                                    <br />
                                    <br />
                                    See the real Mexico by going to less <br />{' '}
                                    visited Parks
                                </p>
                            </div>

                            <div className="col s12">
                                <span>
                                    2. Travel to Parks under threat to make the{' '}
                                    <br /> ecotourism case for conservation.
                                </span>
                                <p>
                                    Under threat of deforestation, development
                                    or mining <br />
                                    <br />
                                    Under threat of being downgraded, downsized
                                    or <br />
                                    having protections removed
                                </p>
                            </div>

                            <div className="col s12">
                                <span>3. Choosing ecotourism businesses:</span>
                                <p>
                                    Whose policies and practices <br />
                                    are good for conservation <br />
                                    <br />
                                    That offer conservation related <br />
                                    educational and participatory experiences{' '}
                                    <br />
                                    <br />
                                    That are community owned and operated
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="secction-3">
                    <div className="row">
                        <div className="col s12">
                            <img
                                src={
                                    '/Assets/Images/Landingsection061200x1800/' +
                                    imagesSection6[0] +
                                    '.jpg'
                                }
                            />
                            <img
                                src={
                                    '/Assets/Images/Landingsection061200x1800/' +
                                    imagesSection6[1] +
                                    '.jpg'
                                }
                            />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default Landing;
