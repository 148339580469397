import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './Pages/Landing';
import ViewPark from './Pages/ViewPark';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import ListParks from './Pages/ListParks';
import ListParkAll from './Pages/ListParkAll';

import 'materialize-css/dist/css/materialize.min.css';

class App extends Component {
    constructor(props) {
        super(props);
        localStorage.setItem(
            'api',
            window.location.hostname === 'localhost'
                ? 'http://localhost:9000/api'
                : 'https://enduringparks.com/api'
        );
    }

    generateNumRandom = (limitNum, numNow) => {
        let numGenerated = Math.round(Math.random() * limitNum);

        while (numGenerated == numNow) {
            numGenerated = Math.round(Math.random() * limitNum);
        }

        return numGenerated;
    };

    setImagesRandom = () => {
        const numPalenque = localStorage.getItem('numRandomPalenque');
        const numCumbresDeMonterrey = localStorage.getItem(
            'numRandomCumbresDeMonterrey'
        );
        const numIztaccihuatlPopocatepetl = localStorage.getItem(
            'numRandomIztaccihuatlPopocatepetl'
        );
        const numLagunasDeMontebello = localStorage.getItem(
            'numRandomLagunasDeMontebello'
        );
        const numLosTuxtlas = localStorage.getItem('numRandomLosTuxtlas');
        const numPicoDeOrizaba = localStorage.getItem('numRandomPicoDeOrizaba');
        const numYaxchilan = localStorage.getItem('numRandomYaxchilan');
        const numTropicalForest = localStorage.getItem(
            'numRandomTropicalForest'
        );

        if (numPalenque) {
            localStorage.setItem(
                'numRandomPalenque',
                this.generateNumRandom(12, numPalenque)
            );
        } else {
            localStorage.setItem('numRandomPalenque', 0);
        }

        if (numCumbresDeMonterrey) {
            localStorage.setItem(
                'numRandomCumbresDeMonterrey',
                this.generateNumRandom(8, numCumbresDeMonterrey)
            );
        } else {
            localStorage.setItem('numRandomCumbresDeMonterrey', 0);
        }

        if (numIztaccihuatlPopocatepetl) {
            localStorage.setItem(
                'numRandomIztaccihuatlPopocatepetl',
                this.generateNumRandom(6, numIztaccihuatlPopocatepetl)
            );
        } else {
            localStorage.setItem('numRandomIztaccihuatlPopocatepetl', 0);
        }

        if (numLagunasDeMontebello) {
            localStorage.setItem(
                'numRandomLagunasDeMontebello',
                this.generateNumRandom(13, numLagunasDeMontebello)
            );
        } else {
            localStorage.setItem('numRandomLagunasDeMontebello', 0);
        }

        if (numLosTuxtlas) {
            localStorage.setItem(
                'numRandomLosTuxtlas',
                this.generateNumRandom(18, numLosTuxtlas)
            );
        } else {
            localStorage.setItem('numRandomLosTuxtlas', 0);
        }

        if (numPicoDeOrizaba) {
            localStorage.setItem(
                'numRandomPicoDeOrizaba',
                this.generateNumRandom(11, numPicoDeOrizaba)
            );
        } else {
            localStorage.setItem('numRandomPicoDeOrizaba', 0);
        }

        if (numYaxchilan) {
            localStorage.setItem(
                'numRandomYaxchilan',
                this.generateNumRandom(12, numYaxchilan)
            );
        } else {
            localStorage.setItem('numRandomYaxchilan', 0);
        }

        if (numTropicalForest) {
            localStorage.setItem(
                'numRandomTropicalForest',
                this.generateNumRandom(4, numTropicalForest)
            );
        } else {
            localStorage.setItem('numRandomTropicalForest', 0);
        }
    };

    checkDayImagesRandom = () => {
        const today = new Date().getDate();
        const daySaved = localStorage.getItem('dayImagesRandom');

        if (!daySaved || daySaved != today) {
            localStorage.setItem('dayImagesRandom', today);
            this.setImagesRandom();
        }
    };

    componentDidMount() {
        this.checkDayImagesRandom();
    }
    // callAPI() {
    //   fetch(localStorage.getItem("api") + "/park/1")
    //     .then(res => res.text())
    //     .then(res => console.log(res))
    //     .catch(err => err);
    // }
    // componentDidMount() {
    //   this.callAPI();
    // }
    render() {
        return (
            <Switch>
                <Route exact path="/p/:id" component={ViewPark} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/services" component={Services} />
                <Route exact path="/listparkall" component={ListParkAll} />
                <Route
                    exact
                    path="/listparks/:textSearch"
                    component={ListParks}
                />
                <Route
                    exact
                    path="/listparks/:textSearch/:designations"
                    component={ListParks}
                />
                <Route exact path="/" component={Landing} />
            </Switch>
        );
    }
}

export default App;
