import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { withRouter } from 'react-router-dom';
import { getImageRandomPark } from '../Functions';
import Select from 'react-select';
import { Link } from "react-router-dom";
import '../Css/Pages/ListParks.css';

class ListParks extends Component {
    state = {
        textSearch: this.props.match.params.textSearch,
        // designations: this.props.match.params.designations.split("-"),
        parks: []
    }

    getParks() {
        fetch(localStorage.getItem("api") + `/park/search/${this.state.textSearch}`, {
            method: "GET"
        }).then(
            (response) => (response.json())
        ).then((response) => {
            this.setState({ parks: response });
        })
    }

    componentDidMount() {
        this.getParks();
    }

    // maxMenuHeight={150} menuPortalTarget={document.body} menuPosition={'fixed'} styles={customStyles}

    render() {
        const { parks } = this.state;
        return (
            <div id="ListParks">
                <Navbar />
                <div className="container">
                    <div className="row">
                        {
                            parks.length > 0 ?
                                parks.map((p, i) => {
                                    return <div className="col s12 m6 l4" key={i}>
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={p.photo === "Personal photos" || p.photo === "Drive" ? `/Assets/Images/parks/${p.name}/${getImageRandomPark(p.name)}.jpg` : p.photo } />
                                                <Link className="btn-floating btn-large halfway-fab waves-effect waves-light"
                                                    to={"/p/" + p.id_park} ><i className="material-icons">landscape</i></Link>
                                            </div>
                                            <div className="card-content">
                                                <span className="card-title">{p.name.substr(0, 45) === p.name ? p.name : p.name.substr(0, 45) + "..."}</span>
                                            </div>
                                        </div>
                                    </div>
                                })
                            :
                                <div className="no-results">
                                    <center><img src="/Assets/Icons/logo-e.png" /></center>
                                    <span>No results were found for your search</span>
                                </div>
                        }
                    </div>
                </div>
                <Footer />
            </div >
        )
    }
}

export default withRouter(ListParks);