import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import M from 'materialize-css';

import '../Css/Global.css';
import '../Css/Components/Navbar.css';
import { Thumbs } from 'react-responsive-carousel';

class Navbar extends Component {
    state = {
        textSearch: "",
        // designations: "",
        options: []
    }

    hiddenOverflowModal = () => {
        document.getElementsByTagName('body')[0].setAttribute('style', '');
    }

    getDesignations() {
        fetch(localStorage.getItem("api") + "/designation", {
            method: "GET"
        }).then(
            (response) => (response.json())
        ).then((response) => {
            const arrDesignation = response.map(designation => {
                return {
                    value: designation.id_designation,
                    label: designation.name
                }
            });
            this.setState({ options: arrDesignation });
        })
    }

    componentDidMount() {
        const sidenav = document.querySelector('.sidenav');
        M.Sidenav.init(sidenav, {});

        var modal = document.querySelector('.modal');
        M.Modal.init(modal, {
            onOpenEnd: () => {
                this.inputTextSearch.focus();
            }
        });

        // this.getDesignations();
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    // handleChange2 = e => {
    //     for (let i = 0; i < e.length; i++) {
    //         this.setState({
    //             designations: this.state.designations + e[i].value + "-"
    //         });
    //     }
    // }

    handleSubmit = e => {
        e.preventDefault();
        let textSearch = this.state.textSearch === "" ? "all" : this.state.textSearch;
        // let designations = this.state.designations === "" ? "-" : this.state.designations;
        this.props.history.push('/listparks/' + textSearch /*+ "/" + designations*/);
        if (window.location.pathname.includes("listpark")) {
            window.location.reload();
        } else {
            this.hiddenOverflowModal();
        }
    }

    render() {
        const { textSearch, options } = this.state;

        const customStyles = {
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({ ...provided, zIndex: 9999 })
        }

        return (
            <div>
                <div id="modalSearch" className="modal" style={{ overflowX: 'hidden' }}>
                    <button id="btn-close-modal" className="modal-close btn-flat" onClick={() => this.hiddenOverflowModal()}>X</button>
                    <br />
                    <div className="modal-content">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                                <div className="col s12 ">
                                    <input ref={ input => { this.inputTextSearch = input } } type="text" placeholder="Search" name="textSearch"
                                        onChange={this.handleChange} defaultValue={textSearch} required />
                                </div>

                                {/* <div className="col s12 offset-m1 m6">
                                    <Select options={options} isMulti onChange={this.handleChange2} placeholder="Select designations" maxMenuHeight={150} menuPortalTarget={document.body} menuPosition={'fixed'} styles={customStyles} />
                                </div> */}
                            </div>
                            <br />
                            <div className="row">
                                <center><input type="submit" value="Search" /></center>
                            </div>
                        </form>
                    </div>
                </div>

                <ul id="slide-out" className="sidenav">
                    {/* <li><Link to="/aboutUs" className="sidenav-close">About Us</Link></li> */}
                    <li><a href="#modalSearch" className="modal-trigger sidenav-close"><i className="material-icons left">search</i></a></li>
                    <li><Link to="/listparkall" className="sidenav-close">Parks</Link></li>
                    <li><Link to="/services" className="sidenav-close">Services</Link></li>
                    <li><Link to="/contact" className="sidenav-close">Contact</Link></li>
                    {/* <li>
                        <a href="collapsible.html" target="_blank"><img src="/Assets/Icons/fb-b.png" /></a>
                        <a href="collapsible.html" target="_blank"><img src="/Assets/Icons/ig-b.png" /></a>
                    </li> */}
                </ul>
                <div className="navbar-fixed">
                    <nav>
                        <div className="nav-wrapper">
                            <a href="#" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                            <Link to="/" className="brand-logo"><img src="/Assets/Icons/logo.png" /></Link>
                            <ul id="nav-mobile" className="right hide-on-med-and-down">
                                <li><a href="#modalSearch" className="modal-trigger"><i className="material-icons left">search</i></a></li>
                                {/* <li><Link to="/aboutUs">About Us</Link></li> */}
                                <li><Link to="/listparkall">Parks</Link></li>
                                <li><Link to="/services">Services</Link></li>
                                <li><Link to="/contact" className="line-white-vertical">Contact</Link></li>
                                {/* <li><a href="collapsible.html" target="_blank"><img src="/Assets/Icons/fb.png" /></a></li>
                                <li><a href="collapsible.html" target="_blank"><img src="/Assets/Icons/ig.png" /></a></li> */}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}

export default withRouter(Navbar);
