import React, { Component } from 'react';
import FormContact from '../Components/FormContact';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class Contact extends Component {
    render() {
        return(
            <div id="Contact">
                <Navbar />
                <FormContact />
                <Footer />
            </div>
        )
    }
}

export default Contact;