import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { getImageRandomPark } from '../Functions';
import { Link } from 'react-router-dom';
import '../Css/Pages/ListParks.css';
import CardPark from '../Components/card-park/CardPark';

class ListParkAll extends Component {
    state = {
        parks: [],
    };

    filterParks = (e) => {
        const value = e.target.value;

        if (value !== '') {
            fetch(localStorage.getItem('api') + `/park/search/${value}`, {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.length > 0) {
                        this.setState({ parks: response });
                    } else {
                        this.setState({ park: [] });
                    }
                });
        } else {
            this.getListParks();
        }
    };

    getListParks = () => {
        fetch(localStorage.getItem('api') + `/park`)
            .then((response) => response.json())
            .then((response) => {
                if (response.length > 0) {
                    this.setState({ parks: response });
                }
            });
    };

    componentDidMount() {
        this.getListParks();
    }

    render() {
        const { parks } = this.state;

        return (
            <div id="ListParks">
                <Navbar />
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <input
                                type="text"
                                placeholder="Filter..."
                                onChange={(e) => this.filterParks(e)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {parks.length > 0 ? (
                            parks.map((p, i) => {
                                return (
                                    <CardPark
                                        name={
                                            p?.name &&
                                            p?.name.substr(0, 45) === p?.name
                                                ? p?.name
                                                : p?.name.substr(0, 45) + '...'
                                        }
                                        photo={
                                            p?.photo === 'Personal photos' ||
                                            p?.photo === 'Drive'
                                                ? `/Assets/Images/parks/${
                                                      p?.name
                                                  }/${getImageRandomPark(
                                                      p?.name
                                                  )}.jpg`
                                                : p?.photo
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div className="no-results">
                                <center>
                                    <img src="/Assets/Icons/logo-e.png" />
                                </center>
                                <span>
                                    No results were found for your search
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ListParkAll;
