import React, { useState } from 'react';
import './map.css';

const Map = (props) => {
    const [mapState, setMapState] = useState(false);

    return (
        <div className={`t-map ${props.className ? props.className : ''}`}>
            {props.children}
            <div
                className={`blok-map-landing ${mapState ? 'close' : ''}`}
            ></div>
            <button
                onClick={() => setMapState(!mapState)}
                className="button-map-landing"
            >
                {mapState ? (
                    <i className="material-icons">no_encryption</i>
                ) : (
                    <i className="material-icons">open_with</i>
                )}
            </button>
        </div>
    );
};

export default Map;
