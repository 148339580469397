import React, { Component } from 'react';
import '../Css/Components/Footer.css';

class Footer extends Component {
    render() {
        return(
            <div id="Footer">
                <footer className="page-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col l6 s12">
                                <img className="logo" src="/Assets/Icons/logo.png" />
                                {/* <h5 className="white-text">Footer Content</h5>
                                <p className="grey-text text-lighten-4">You can use rows and columns here to organize your footer content.</p> */}
                            </div>
                            <div className="col l4 offset-l2 s12">
                                <div>
                                    <h5 className="white-text">Contact</h5>
                                    <ul>
                                        <li><a className="grey-text text-lighten-3" href="mailto:enduringparks@gmail.com">enduringparks@gmail.com</a></li>
                                        <li><a className="grey-text text-lighten-3" target="_blank" href="https://wa.me/+18308227103">+1 830-822-7103</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="container">
                            <center>Copyright ©️ { new Date().getFullYear() } <a href="https://secuenciauno.co/" target="_blank">Secuencia Uno</a></center>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;