const getImageRandomPark = namePark => {
    let valReturn = 0;

    if (localStorage.getItem('numRandomPalenque') && namePark === "Palenque") {
        valReturn = localStorage.getItem('numRandomPalenque');
    }else if (localStorage.getItem('numRandomCumbresDeMonterrey') && namePark === "Cumbres de Monterrey") {
        valReturn = localStorage.getItem('numRandomCumbresDeMonterrey');
    }else if (localStorage.getItem('numRandomIztaccihuatlPopocatepetl') && namePark === "Iztaccíhuatl-Popocatépetl") {
        valReturn = localStorage.getItem('numRandomIztaccihuatlPopocatepetl');
    }else if (localStorage.getItem('numRandomLagunasDeMontebello') && namePark === "Lagunas de Montebello") {
        valReturn = localStorage.getItem('numRandomLagunasDeMontebello');
    }else if (localStorage.getItem('numRandomLosTuxtlas') && namePark === "Los Tuxtlas") {
        valReturn = localStorage.getItem('numRandomLosTuxtlas');
    }else if (localStorage.getItem('numRandomPicoDeOrizaba') && namePark === "Pico de Orizaba") {
        valReturn = localStorage.getItem('numRandomPicoDeOrizaba');
    }else if (localStorage.getItem('numRandomYaxchilan') && namePark === "Yaxchilán") {
        valReturn = localStorage.getItem('numRandomYaxchilan');
    }

    return valReturn;
}

const stringToHTML =  (str) => {
    document.getElementById("iframe").innerHTML = str;
};

export {
    getImageRandomPark, stringToHTML
};