import React from 'react';
import './CardPark.css';

const CardPark = (props) => {
    const { name, photo } = props;

    return (
        <div className="card-park col s12 m6 l4">
            <div className="card">
                <div className="card-image">
                    <a href={'/p/' + name}>
                        <img src={photo} />
                    </a>
                    <a
                        className="btn-floating btn-large halfway-fab waves-effect waves-light"
                        href={'/p/' + name}
                    >
                        <i className="material-icons">landscape</i>
                    </a>
                </div>
                <div className="card-content">
                    <span className="card-title">{name}</span>
                </div>
            </div>
        </div>
    );
};

export default CardPark;
