import React from 'react';
import '../Css/Components/CardService.css';

const CardService = (props) => {
    const { services } = props;

    return (
        <div id="CardService" className='container'>
            {
                services.map(s =>
                    <div className="column" key={s.id_service}>
                        <div className="card-services" >
                            <div className="card-header">
                                {s.name}
                            </div>
                            <div className="card-body">
                                <p>{s.description}</p>
                                <p>{s.contact_number}</p>
                            </div>
                            <div className='card-footer'>
                                <span>{s.location}</span><br></br>
                                {s.website && <a href={s.website} target='_blanck'>Visit us</a>}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CardService;
