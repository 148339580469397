import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import { getImageRandomPark, stringToHTML } from '../Functions';
import '../Css/Pages/ViewPark.css';
import { getParksByName } from '../services/park';
import CardPark from '../Components/card-park/CardPark';
import CardService from '../Components/CardService';
import Map from '../Components/templates/map/map';

class ViewPark extends Component {
    state = {
        textSearch: this.props.match.params.id,
        park: null,
        parksRelacionados: [],
        services: [],
    };

    convertArrayToObject(array, key) {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };

    getParksRelacionados(name) {
        getParksByName(name)
            .then((response) => response.json())
            .then((response) => {
                this.setState({
                    parksRelacionados: [
                        ...this.state.parksRelacionados,
                        response[0],
                    ],
                });
            });
    }

    getParkByID(id) {
        fetch(
            localStorage.getItem('api') + `/park/${id}`,
            {
                method: 'GET',
            }
        )
            .then((response) => response.json())
            .then((response) => {
                this.setState({ park: response });
                this.getServicesByPark(response.id_park);
                let relacionados =
                    response.suggested_alternates_terrestrial &&
                    response.suggested_alternates_terrestrial.split(',');

                if (relacionados != undefined) {
                    relacionados.map((name) =>
                        this.getParksRelacionados(name)
                    );
                }
            });
    }

    getParkByName(name) {
        fetch(
            localStorage.getItem('api') + `/park/name/${name}`,
            {
                method: 'GET',
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response.length > 0) {
                    // Extrae el data de los objeto tipo buffer
                    let res = {}
                    Object.keys(response[0]).map(r => {
                        if (response[0][r] && typeof response[0][r] === 'object' && 'data' in response[0][r])
                            res[r] = response[0][r].data[0]
                        else
                            res[r] = response[0][r]
                    })
                    return res
                } else {
                    return null
                }
            })
            .then((response) => {
                if (!response) {
                    return
                }
                this.setState({ park: response });
                this.getServicesByPark(response.id_park);
                let relacionados =
                    response.suggested_alternates_terrestrial &&
                    response.suggested_alternates_terrestrial.split(',');

                if (relacionados != undefined) {
                    relacionados.map((name) =>
                        this.getParksRelacionados(name)
                    );
                }
            });
    }

    getPark() {
        let param = this.state.textSearch
        if (param.length < 4) {
            this.getParkByID(param)
            return
        }
        this.getParkByName(param)
    }

    /**
     * Get the services of the business that works in the park
     */
    getServicesByPark(id_park) {
        fetch(
            localStorage.getItem('api') + `/service/park/${id_park}`,
            {
                method: 'GET',
            }
        ).then((response) => response.json())
            .then((response) => {
                this.setState({
                    services: response,
                })
            })
    }

    componentDidMount() {
        this.getPark();
    }

    render() {
        const { park, parksRelacionados } = this.state;
        if (!park || Object.keys(park).length < 1) {
            return (
                <div className="no-results">
                    <center><img src="/Assets/Icons/logo-e.png" /></center>
                    <span>No results were found for your search</span>
                </div>
            )
        }
        let listPark = '';
        let imagen = '';
        let image = new Image();

        image.onload = function () {
            imagen = image.src;
        };
        image.onerror = function () {
            imagen = '/Assets/Images/parks/tuxtas.jpg';
        };

        image.src = park?.photo;

        if (
            park.mpa_visitation_level_idmpa_visitation_level === 'Moderate' ||
            park.mpa_visitation_level_idmpa_visitation_level === 'High'
        ) {
            listPark = park.suggested_alternates_mpa;
        } else if (
            park.terrestrial_visitation_level_idterrestrial_visitation_level ===
            'Moderate' ||
            park.terrestrial_visitation_level_idterrestrial_visitation_level ===
            'High'
        ) {
            listPark = park.suggested_alternates_terrestrial;
        }

        return (
            <div id="ViewPark">
                <Navbar />

                <div className="header-park">
                    <img
                        src={
                            park.photo && park.photo !== ''
                                ? park.photo === 'Personal photos' ||
                                    park.photo === 'Drive'
                                    ? `/Assets/Images/parks/${park.name
                                    }/${getImageRandomPark(park.name)}.jpg`
                                    : park.photo
                                : '/Assets/Images/image-secction-1-park.jpg'
                        }
                        onError={() =>
                            this.setState({
                                park: { ...this.state.park, photo: '' },
                            })
                        }
                    />
                    <div>
                        <h1>{park.name}</h1>
                        {park.mpa_visitation_level_idmpa_visitation_level ===
                            'Low' ||
                            park.terrestrial_visitation_level_idterrestrial_visitation_level ===
                            'Low' ? (
                            <p>
                                Seldom visited. Need help planning? We can help.
                            </p>
                        ) : (
                            ''
                        )}
                        <p>
                            {park.designation} in {park.state} - Mexico
                            <br />
                            {park.in_lonely_planet &&
                                park.in_lonely_planet === 1 &&
                                '* Found in Lonely Planet *'}
                        </p>
                    </div>
                </div>

                <Map>
                    <div className="iframe" dangerouslySetInnerHTML={{ __html: park.template_map }}></div>
                </Map>

                <div id="secction-1">
                    <img
                        src={
                            park.photo && park.photo !== ''
                                ? park.photo === 'Personal photos' ||
                                    park.photo === 'Drive'
                                    ? `/Assets/Images/parks/${park.name
                                    }/${getImageRandomPark(park.name)}.jpg`
                                    : park.photo
                                : '/Assets/Images/image-secction-1-park.jpg'
                        }
                        onError={() =>
                            this.setState({
                                park: { ...this.state.park, photo: '' },
                            })
                        }
                    />
                    <div>
                        {park.mpa && park.mpa === 1 ? (
                            <span>This is a Marine Protected Area</span>
                        ) : (
                            <span className="span-empty"></span>
                        )}

                        {park.mpa_visitation_level_idmpa_visitation_level ===
                            'Moderate' ||
                            park.mpa_visitation_level_idmpa_visitation_level ===
                            'High' ||
                            park.terrestrial_visitation_level_idterrestrial_visitation_level ===
                            'Moderate' ||
                            park.terrestrial_visitation_level_idterrestrial_visitation_level ===
                            'High' ? (
                            <span>
                                This Protected Area receives a lot of visitors.{' '}
                                <br />
                                Consider visiting {listPark}
                            </span>
                        ) : (
                            <span className="span-empty"></span>
                        )}
                    </div>
                </div>

                <div id="secction-2">
                    <div>
                        <h3>Level of visitation</h3>

                        <img
                            src={
                                park.terrestrial_visitation_level_idterrestrial_visitation_level ||
                                    park.mpa_visitation_level_idmpa_visitation_level
                                    ? (park.terrestrial_visitation_level_idterrestrial_visitation_level ==
                                        'High' &&
                                        '/Assets/Images/High.png') ||
                                    (park.terrestrial_visitation_level_idterrestrial_visitation_level ==
                                        'Moderate' &&
                                        '/Assets/Images/Moderate.png') ||
                                    (park.terrestrial_visitation_level_idterrestrial_visitation_level ==
                                        'Low' &&
                                        '/Assets/Images/Low.png')
                                    : '/Assets/Images/Low.png'
                            }
                        />

                        {park.mpa && park.mpa === '1' ? (
                            <h5>
                                {' '}
                                {
                                    park.mpa_visitation_level_idmpa_visitation_level
                                }{' '}
                            </h5>
                        ) : park.terrestrial_visitation_level_idterrestrial_visitation_level ==
                            null ? (
                            <h5> Low </h5>
                        ) : (
                            <h5>
                                {' '}
                                {
                                    park.terrestrial_visitation_level_idterrestrial_visitation_level
                                }{' '}
                            </h5>
                        )}
                    </div>
                    <div>
                        <h3>Accessibility Rating</h3>
                        <img src="/Assets/Images/accessibilityrating.png" />
                        <h5>
                            Not yet evaluated.
                            <br /> Help fund this Park's accessibility.
                        </h5>
                    </div>
                </div>

                <div id="secction-3">
                    {park.world_heritage_site_in_danger &&
                        park.world_heritage_site_in_danger !== '' && (
                            <div className="sub-secction-3">
                                <div className="body-card">
                                    <img src="/Assets/Images/image-secction3-template.jpg" />
                                    <span className="text-header-card">
                                        Protected area under threat
                                    </span>
                                    <p>Learn about</p>
                                    <div className="sub-card-left">
                                        <button>Development Threats</button>
                                    </div>
                                    <div className="sub-card-center">
                                        <a
                                            href={
                                                park.world_heritage_site_in_danger
                                            }
                                            target="_blank"
                                        >
                                            <button>
                                                This PADDD Protected Area
                                            </button>
                                        </a>
                                        <img src="/Assets/Images/icon-alert-template-green.png" />
                                    </div>
                                    <div className="sub-card-right">
                                        <button>
                                            Threats to This World Heritage Site
                                        </button>
                                    </div>
                                    <span>
                                        Can your ecotourism help <br /> ward off
                                        this threat?
                                    </span>
                                </div>
                                <img
                                    className="image-card"
                                    src="/Assets/Images/img-3668-.jpg"
                                />
                            </div>
                        )}
                    {park.world_heritage_site &&
                        park.world_heritage_site !== '' && (
                            <div className="sub-secction-3 order-reverse">
                                <div className="body-card">
                                    <span className="text-header-card">
                                        Conservation importance
                                    </span>
                                    <img src="/Assets/Images/image-2-secction3-template.jpg" />
                                    <p>Learn about</p>
                                    <div className="sub-card-right button-color-crimson">
                                        <a
                                            href={park.aze_site_2018}
                                            target="_blank"
                                        >
                                            <button>This AZE Site</button>
                                        </a>
                                        <img src="/Assets/Images/icon-alert-template-brown.png" />
                                    </div>
                                    <div className="sub-card-center button-border-color-crimson">
                                        <a
                                            href={park.world_heritage_site}
                                            target="_blank"
                                        >
                                            <button>
                                                This World Heritage Site
                                            </button>
                                        </a>
                                    </div>
                                    <div className="sub-card-left button-color-crimson">
                                        <button>This IBA Site</button>
                                        <img src="/Assets/Images/icon-alert-template-brown.png" />
                                    </div>
                                </div>
                                <img
                                    className="image-card"
                                    src="/Assets/Images/img-3669.jpg"
                                />
                            </div>
                        )}
                </div>

                <div id="secction-4">
                    <img src="/Assets/Images/Lostuxltas-1.jpg" />
                    <img src="/Assets/Images/Lostuxltas-2.jpg" />
                    <div>
                        Visit because This Park
                        <br />
                        is very important
                        <br />
                        for many species
                        <br />
                        and the world
                        <hr />
                    </div>
                    <img src="/Assets/Images/Lostuxltas-3.jpg" />
                    <img src="/Assets/Images/Lostuxltas-4.jpg" />
                </div>

                <div id="opportunities">
                    <div className="sub-sec5-1">
                        <img src="/Assets/Images/opportunities-background.jpg" />
                        <span>opportunities</span>
                    </div>

                    <div className="sub-sec5-2">
                        {park.tropical_forest_rainforest &&
                            park.tropical_forest_rainforest === 1 && (
                                <div>
                                    <img
                                        src={`/Assets/Images/parks/features/tropicalforest/${localStorage.getItem(
                                            'numRandomTropicalForest'
                                        ) || 0
                                            }.jpg`}
                                    />
                                    <div>Tropical Forest / Rainforesrt</div>
                                </div>
                            )}

                        {park.high_elevation_or_non_tropical_forest &&
                            park.high_elevation_or_non_tropical_forest === 1 && (
                                <div>
                                    <img src="/Assets/Images/IMG_4083.JPG" />
                                    <div>
                                        High Elevation or Non-Tropical Forest
                                    </div>
                                </div>
                            )}

                        {park.canyons && park.canyons === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/48.jpg" />
                                <div>Canyons</div>
                            </div>
                        )}

                        {park.caves && park.caves === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/51.jpg" />
                                <div>Caves</div>
                            </div>
                        )}

                        {park.island && park.island === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/49.jpg" />
                                <div>Island</div>
                            </div>
                        )}

                        {park.beaches && park.beaches === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/50.jpg" />
                                <div>Beaches</div>
                            </div>
                        )}

                        {park.deserts && park.deserts === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/42.jpg" />
                                <div>Deserts</div>
                            </div>
                        )}

                        {park.mangroves && park.mangroves === 1 && (
                            <div>
                                <img src="/Assets/Images/Mangroves.jpg" />
                                <div>Mangroves</div>
                            </div>
                        )}

                        {park.volcanoes && park.volcanoes === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/33.jpg" />
                                <div>Volcanoes</div>
                            </div>
                        )}

                        {park.wildlife && park.wildlife === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/30.jpg" />
                                <div>Wildlife</div>
                            </div>
                        )}

                        {park.historic_site &&
                            park.historic_site === 1 && (
                                <div>
                                    <img src="/Assets/Images/2400_/44.jpg" />
                                    <div>Historic Sites</div>
                                </div>
                            )}

                        {park.indigenous_cultural &&
                            park.indigenous_cultural === 1 && (
                                <div>
                                    <img src="/Assets/Images/2400_/2.jpg" />
                                    <div>Indigenous/Cultural Experiences</div>
                                </div>
                            )}

                        {park.waterfalls && park.waterfalls === 1 && (
                            <div>
                                <img src="/Assets/Images/Waterfalls.jpg" />
                                <div>Waterfalls</div>
                            </div>
                        )}

                        {park.reefs && park.reefs === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/52.jpg" />
                                <div>Reefs</div>
                            </div>
                        )}

                        {park.mountains && park.mountains === 1 && (
                            <div>
                                <img src="/Assets/Images/2400_/5.jpg" />
                                <div>Mountains</div>
                            </div>
                        )}

                        <div>
                            <img src="/Assets/Images/2400_/53.jpg" />
                            <div>Other unique attraccions</div>
                        </div>
                    </div>
                </div>
                {this.state.services.length > 0 &&
                    <div id="services">
                        <div className="banner">
                            <h4>Services</h4>
                        </div>
                        <CardService services={this.state.services} key={0}></CardService>
                    </div>
                }

                <div id="secction-6">
                    <div>
                        <div>
                            <img src="/Assets/Images/thebeatenpath.jpg" />
                            <span>
                                Off the beaten path but well worth taking a look
                            </span>
                        </div>
                        {(park.iucn_green_list &&
                            park.iucn_green_list.length > 0) ||
                            (park.global_top_100_destination_2018 &&
                                park.global_top_100_destination_2018.length > 0) ? (
                            <span>Learn about</span>
                        ) : (
                            ''
                        )}
                    </div>

                    <div>
                        {park.iucn_green_list &&
                            park.iucn_green_list !== '' && (
                                <div>
                                    <div>
                                        <div>
                                            <a
                                                target="_blank"
                                                href={park.iucn_green_list}
                                            >
                                                IUCN Green List Protected Area
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                        {park.global_top_100_destination_2018 &&
                            park.global_top_100_destination_2018 !== '' && (
                                <div>
                                    <div>
                                        <div>
                                            <a
                                                target="_blank"
                                                href={
                                                    park.global_top_100_destination_2018
                                                }
                                            >
                                                Green Destinations Top 100
                                                Sustainable Destinations
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>

                    <div>
                        <span>
                            Explore the fauna and flora of {park.name} at
                        </span>
                    </div>

                    <div>
                        <div>
                            <button>English</button>
                            <iframe
                                src={
                                    'https://www.inaturalist.org/places/guide_widget/' +
                                    park.inaturalist
                                }
                            ></iframe>
                        </div>

                        <div>
                            <button>Español</button>
                            <iframe
                                src={
                                    'https://www.naturalista.mx/places/guide_widget/' +
                                    park.naturalist
                                }
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div id="secction-7">
                    <div>
                        <center>
                            <img src="/Assets/Images/nounbotany.png" />
                            <br />
                            <span>
                                Did we get it wrong? Submit a correction here.
                            </span>
                            <br />
                            <br />
                            <textarea></textarea>
                            <br />
                            <br />
                        </center>
                        <div>
                            <div>
                                Submitted corrections must include supporting
                                documentation
                            </div>
                            <img src="/Assets/Images/send-icon.png" />
                        </div>
                    </div>
                </div>

                <div id="secction-9">
                    <h2 className="m4">Related parks</h2>
                    <div className="row">
                        {parksRelacionados.length > 0 ? (
                            parksRelacionados.map((p, i) => {
                                return (
                                    <CardPark
                                        key={i}
                                        name={
                                            p?.name &&
                                                p?.name.substr(0, 45) === p?.name
                                                ? p?.name
                                                : p?.name.substr(0, 45) + '...'
                                        }
                                        photo={
                                            p?.photo === 'Personal photos' ||
                                                p?.photo === 'Drive'
                                                ? `/Assets/Images/parks/${p?.name
                                                }/${getImageRandomPark(
                                                    p?.name
                                                )}.jpg`
                                                : p?.photo
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div className="no-results">
                                <center>
                                    <img src="/Assets/Icons/logo-e.png" />
                                </center>
                                <span>No related parks found</span>
                            </div>
                        )}
                    </div>
                </div>

                <div id="secction-8">
                    <h3>
                        This is a Park in a country where many areas are still
                        developing. Need help getting around? Find a restaurant?
                        EP can help.
                    </h3>
                </div>

                <Footer />
            </div>
        );
    }
}

export default ViewPark;
