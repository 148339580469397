import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import FormContact from '../Components/FormContact';
import Footer from '../Components/Footer';
import '../Css/Pages/Services.css';

class Services extends Component {
    render() {
        return(
            <div id="Services">
                <Navbar />
                <div className="container-fluid">
                    <div>
                        <div><h1>Services</h1></div>
                        <div>
                            <ul>
                                <li>
                                    Need help finding accommodations or a tour guide?
                                    <ul>
                                        <li>
                                            If the Park is frequently visited, many options exist.  Let us help you find the option who works best for conservation.
                                        </li>
                                        <li>
                                            If the Park receives few visitors, accommodations may be limited.  We can help you find dependable options near the Park.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Need an on the ground opinion of the current safety situation?
                                    <ul>
                                        <li>
                                            Our in country advisors can help provide you with an informal opinion of the safety situation.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Need information on how to get to your destination?
                                    <ul>
                                        <li>
                                            Our in country advisors can help provide you with the information you need to make travel decisions and get you where you need to get.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div>
                            Note: we have no financial interest in any ecotourism business or Park.  We recognize the value in having information that sometimes leads to NOT visiting a Park.
                        </div>
                        <br/><br/>
                        <div>
                            <center>Contact us below to let us know your need.</center>
                        </div>
                    </div>
                </div>
                <FormContact />
                <Footer />
            </div>
        )
    }
}

export default Services;